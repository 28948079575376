<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters sticky-filter" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <div class="col-md-4">
              <v-select
                class="v-select-filter"
                placeholder="Select log type.."
                v-model="filters.activities.logName"
                :reduce="t => t.value"
                :options="[
                  {value: 'admin', label: 'Admin Logs' },
                  {value: 'crm_emails', label: 'CRM Email Logs' },
                  {value: 'crm_tasks', label: 'Task Logs' },
                  {value: 'restaurant', label: 'Restaurant Logs' },
                ]"
                @input="logTypeFilter"
              />
            </div>

            <!-- Subject Filter -->
            <div class="col-md-8 pl-md-0 mt-2 mt-md-0">
              <v-select
                class="v-select-filter"
                placeholder="Select subject.."
                v-model="filters.activities.selectedSubject"
                :disabled="filters.activities.logName != 'restaurant'"
                :options="allRestaurants"
                @input="applyFilter"
              />
            </div>
          </div>

          <div class="row mt-2">
            <!-- Causer Type -->
            <div class="col-md-4">
              <v-select
                class="v-select-filter"
                placeholder="Select causer type.."
                v-model="filters.activities.causerType"
                :reduce="t => t.value"
                :options="[
                  {value: 'User', label: 'User' },
                  {value: 'Restaurant', label: 'Restaurant' },
                ]"
                @input="causerTypeFilter"
              />
            </div>

            <!-- Causer Filter -->
            <div class="col-md-8 pl-md-0 mt-2 mt-md-0">
              <v-select
                class="v-select-filter"
                placeholder="Select a causer.."
                v-model="filters.activities.selectedCauser"
                :disabled="!filters.activities.causerType"
                :options="causers"
                @input="applyFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      clickable-rows
      :items="logs"
      :fields="fields"
      :sorter="{ external: false, resetable: true }"
      :column-filter="{ external: true, lazy: true }"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No activities found!',
      }"
      @row-clicked="rowClicked"
    >
      <!-- @update:sorter-value="sorterValue" -->

      <!-- ID -->
      <template #id="{item}">
        <td>
          #{{ item.id }}
        </td>
      </template>

      <!-- Causer -->
      <template #causer="{ item }">
        <td>
          <div v-if="item.causer">
            <CLink v-if="item.causer_type == 'App\\Models\\User'"
              :to="{ name: 'User', params: { id: item.causer_id } }">
              {{ item.causer.first_name }} {{ item.causer.last_name }}
            </CLink>
            <CLink v-else-if="item.causer_type == 'App\\Models\\Restaurant'"
              :to="{ name: 'View Restaurant', params: { id: item.causer_id } }">
              <CIcon name="cil-restaurant" /> {{ item.causer.restaurant_name }}
            </CLink>
          </div>
        </td>
      </template>

      <!-- Date Filter -->
      <template #created_at-filter>

        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text p-1 w-min">From</span>
          </div>
          <input
            id="date-filter-from"
            type="date"
            v-model="filters.activities.startDate"
            class="w-min"
          />
        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text p-1 w-min">To</span>
          </div>
          <input
            id="date-filter-from"
            type="date"
            v-model="filters.activities.endDate"
            class="w-min"
          />
        </div>
      </template>

      <!-- Date (created_at) -->
      <template #created_at="{item}">
        <td :class="isToday(item.created_at) ? 'text-danger font-weight-bold' : ''">
          {{ new Date(item.created_at).toLocaleString() }}
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{item}">
        <td class="py-2 text-center">
          <CButton
            size="sm"
            color="primary"
            v-c-tooltip="{
              html: true,
              content: 'Download'
            }"
          >
            <CIcon name="cil-cloud-download" />
          </CButton>

          <CButton
            size="sm"
            color="info"
            class="ml-1"
            v-c-tooltip="{
              html: true,
              content: 'Send E-Mail'
            }"
          >
            <CIcon name="cil-send" />
          </CButton>
        </td>
      </template>

      <!-- Show Details -->
      <template #show_details="{ item, index }">
        <td class="py-2">
          <CButton
            color="link"
            @click="toggleDetails(item, index)"
          >
            <span v-if="Boolean(item._toggled)"><CIcon name="cil-chevron-circle-up-alt"/> Hide</span>
            <span v-else><CIcon name="cil-chevron-circle-down-alt"/> Details</span>
          </CButton>
        </td>
      </template>

      <!-- Details -->
      <template #details="{ item, index }">
        <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
          <CCardBody>
            <CLink v-if="item.subject"
              :to="subjectLink(item)"
              class="font-weight-bold"
            >Go to subject <CIcon name="cil-arrow-right" />
            </CLink>

            <json-viewer
              :value="item.properties"
              :expand-depth=2
              copyable
            >
            </json-viewer>
          </CCardBody>
        </CCollapse>
      </template>

      <!-- Batch ID -->
      <template #batch_uuid="{item}">
        <td>
          {{ item.batch_uuid }}
        </td>
      </template>
    </CDataTable>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ to }} / {{ total }}</span></h5>
          </div>
          <!--
          <div class="action">

          </div>

          <div class="ml-auto">

          </div>
           -->
        </div>
      </CCardBody>
    </CCard>

    <mc-spinner :opacity="0.8" v-show="loading" :mtop="(250 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>

<script>
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import JsonViewer from 'vue-json-viewer'

export default {
  name: "BackendTable",
  components: {
    vSelect,
    JsonViewer
  },
  data() {
    return {
      logs: [],
      fields: [
        { key: "show_details",  label: "",            filter: false, sorter: false, _style: "min-width: 100px" },
        { key: "id",            label: "ID",          filter: false, _style: "min-width: 60px;" },
        { key: "log_name",      label: "Log Name",    filter: false, _style: "min-width: 120px;", sorter: false },
        { key: "causer",        label: "Causer",      filter: false, _style: "min-width: 120px;width: 180px;", sorter: false },
        { key: "created_at",    label: "Date",        filter: true, _style: "min-width: 160px;" },
        { key: "event",         label: "Event",       filter: false, _style: "min-width: 120px;", sorter: false },

        { key: "description",   label: "Description", filter: false, _style: "min-width: 300px;", sorter: false },
        { key: "batch_uuid",    label: "Batch ID",    filter: false, _style: "width: 200px;", sorter: false }
      ],

      activePage: 1,
      pages: 1,
      to: 0, // Current total
      total: 0,
      itemsPerPage: 50,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Filter işlemleri için
      causers: [],
      allAdminUsers: [],
      allRestaurants: [],

      // Show Details
      collapseDuration: 0,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },

  async mounted() {
    this.loading = true;
    await this.getAllRestaurants();
    await this.getAllAdminUsers();

    await this.getNextLogs();
    await this.causerTypeFilter(this.filters.activities.causerType, this.filters.activities.selectedCauser);
    // await this.getLogs();
  },

  watch: {
    dateFilter() {
      this.getLogs(true);
    }
  },

  computed: {
    ...mapState(['filters']),
    dateFilter() { return [this.filters.activities.startDate, this.filters.activities.endDate] }
  },

  methods: {
    async getLogs(filtered) {

      if (filtered) {
        this.activePage = 1
        this.logs = []
      }

      this.loading = true;

      var url = new URL(this.$backend.ACTIVITIES.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,

          log_name: this.filters.activities.logName,
          causer_type: this.filters.activities.causerType,
          causer: this.filters.activities.selectedCauser?.value,
          subject: this.filters.activities.selectedSubject?.value,
          date_from: this.filters.activities.startDate,
          date_to: this.filters.activities.endDate
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.logs = this.logs.concat(items.data);
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.to = items.to;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getNextLogs() { // Called when mounted
      window.onscroll = () => {
        this.mcSpinnerMarginTop = document.documentElement.scrollTop;
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight-1;

        if (bottomOfWindow) {
          if (this.activePage < this.pages) {
            this.activePage++;
            this.getLogs();
          }
        }
      }
    },

    async getAllRestaurants() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.allRestaurants = response.data;
        })
        .catch(() => { this.allRestaurants = [] })
    },

    async getAllAdminUsers() {
      await this.$axios
        .get(this.$backend.USERS.GET_ADMIN_USERS)
        .then((response) => {
          this.allAdminUsers = response.data;
        })
        .catch(() => { this.allAdminUsers = [] })
    },

    rowClicked(item, index, column, e) {
      // INPUT, LABEL, BUTTON vb... dışında herhangi bir satır nesnesine tıklanırsa;
      if (!['INPUT', 'LABEL', 'BUTTON', 'svg', 'path', 'A', 'IMG', 'SPAN', 'DIV'].includes(e.target.tagName)) {
        this.toggleDetails(item)
      }
    },

    toggleDetails(item) {
      this.$set(item, "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },

    subjectLink(item) {
      const model = item.subject_type.split("\\").pop();

      switch (model) {
        case 'User': return { name: 'User', params: { id: item.subject.id } };
        case 'Restaurant': return { name: 'View Restaurant', params: { id: item.subject.id } };
        case 'Email': return { name: 'Message', params: { id: item.subject.message_id.match(/^([^@]*)@/)[1], folder: 'inbox' } };
        case 'Task': return { name: 'Message', params: { id: item.subject.task_id, folder: 'inbox' } };
        default: return null;
      }
    },

    // Filters
    logTypeFilter(value) {
      if (value != "restaurant" && this.filters.activities.selectedSubject) {
        this.filters.activities.selectedSubject = null;
      }

      this.getLogs(true)
    },
    causerTypeFilter(value, causer = null) {
      if (value == "User") {
        this.causers = this.allAdminUsers;
      } else if (value == "Restaurant") {
        this.causers = this.allRestaurants;
      } else {
        this.causers = [];
      }

      this.filters.activities.selectedCauser = causer;
      this.getLogs(true);
    },
    applyFilter(value) {
      this.getLogs(true)
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.filters.activities.selectedSubject = null;
        this.filters.activities.causerType = null;
        this.filters.activities.selectedCauser = null;
        this.getLogs(true);
      }
      this.$store.commit('toggle', 'filterShow');
    },

    // Helper functions
    isToday(dateString) {
      const someDate = new Date(dateString);
      const today = new Date()
      return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
    }
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
@media (min-width: 576px) {
  input[aria-label='table filter input'] {
    width: 280px !important;
  }
}
</style>

<style scoped>
/* Date Filter */
span.w-min {
  width: 48px;
}
input.w-min {
  width: 100px;
}
input[type="date" i],
input[type="datetime-local" i] {
  color: #f9b115;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  padding-left: 4px;
  margin: 0;
}

.actions .action {
  border-right: 1px solid #d8dbe0 !important;
  padding-right: 0.75rem !important;
}

.sticky-filter {
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  top: 100px;

  /* margin-top: 1.5rem;
  padding: 0.5rem;
  border-radius: 5px; */
}
</style>
