var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        {
          staticClass: "filters sticky-filter",
          attrs: { "accent-color": "warning" },
        },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Select log type..",
                          reduce: (t) => t.value,
                          options: [
                            { value: "admin", label: "Admin Logs" },
                            { value: "crm_emails", label: "CRM Email Logs" },
                            { value: "crm_tasks", label: "Task Logs" },
                            { value: "restaurant", label: "Restaurant Logs" },
                          ],
                        },
                        on: { input: _vm.logTypeFilter },
                        model: {
                          value: _vm.filters.activities.logName,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters.activities, "logName", $$v)
                          },
                          expression: "filters.activities.logName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-8 pl-md-0 mt-2 mt-md-0" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Select subject..",
                          disabled:
                            _vm.filters.activities.logName != "restaurant",
                          options: _vm.allRestaurants,
                        },
                        on: { input: _vm.applyFilter },
                        model: {
                          value: _vm.filters.activities.selectedSubject,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.filters.activities,
                              "selectedSubject",
                              $$v
                            )
                          },
                          expression: "filters.activities.selectedSubject",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Select causer type..",
                          reduce: (t) => t.value,
                          options: [
                            { value: "User", label: "User" },
                            { value: "Restaurant", label: "Restaurant" },
                          ],
                        },
                        on: { input: _vm.causerTypeFilter },
                        model: {
                          value: _vm.filters.activities.causerType,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters.activities, "causerType", $$v)
                          },
                          expression: "filters.activities.causerType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-8 pl-md-0 mt-2 mt-md-0" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Select a causer..",
                          disabled: !_vm.filters.activities.causerType,
                          options: _vm.causers,
                        },
                        on: { input: _vm.applyFilter },
                        model: {
                          value: _vm.filters.activities.selectedCauser,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.filters.activities,
                              "selectedCauser",
                              $$v
                            )
                          },
                          expression: "filters.activities.selectedCauser",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          striped: "",
          hover: "",
          "clickable-rows": "",
          items: _vm.logs,
          fields: _vm.fields,
          sorter: { external: false, resetable: true },
          "column-filter": { external: true, lazy: true },
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No activities found!",
          },
        },
        on: { "row-clicked": _vm.rowClicked },
        scopedSlots: _vm._u([
          {
            key: "id",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" #" + _vm._s(item.id) + " ")])]
            },
          },
          {
            key: "causer",
            fn: function ({ item }) {
              return [
                _c("td", [
                  item.causer
                    ? _c(
                        "div",
                        [
                          item.causer_type == "App\\Models\\User"
                            ? _c(
                                "CLink",
                                {
                                  attrs: {
                                    to: {
                                      name: "User",
                                      params: { id: item.causer_id },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.causer.first_name) +
                                      " " +
                                      _vm._s(item.causer.last_name) +
                                      " "
                                  ),
                                ]
                              )
                            : item.causer_type == "App\\Models\\Restaurant"
                            ? _c(
                                "CLink",
                                {
                                  attrs: {
                                    to: {
                                      name: "View Restaurant",
                                      params: { id: item.causer_id },
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-restaurant" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(item.causer.restaurant_name) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "created_at-filter",
            fn: function () {
              return [
                _c("div", { staticClass: "input-group mb-1" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text p-1 w-min" }, [
                      _vm._v("From"),
                    ]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.activities.startDate,
                        expression: "filters.activities.startDate",
                      },
                    ],
                    staticClass: "w-min",
                    attrs: { id: "date-filter-from", type: "date" },
                    domProps: { value: _vm.filters.activities.startDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filters.activities,
                          "startDate",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "input-group" }, [
                  _c("div", { staticClass: "input-group-prepend" }, [
                    _c("span", { staticClass: "input-group-text p-1 w-min" }, [
                      _vm._v("To"),
                    ]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.activities.endDate,
                        expression: "filters.activities.endDate",
                      },
                    ],
                    staticClass: "w-min",
                    attrs: { id: "date-filter-from", type: "date" },
                    domProps: { value: _vm.filters.activities.endDate },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.filters.activities,
                          "endDate",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "created_at",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  {
                    class: _vm.isToday(item.created_at)
                      ? "text-danger font-weight-bold"
                      : "",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(new Date(item.created_at).toLocaleString()) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "py-2 text-center" },
                  [
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              html: true,
                              content: "Download",
                            },
                            expression:
                              "{\n            html: true,\n            content: 'Download'\n          }",
                          },
                        ],
                        attrs: { size: "sm", color: "primary" },
                      },
                      [_c("CIcon", { attrs: { name: "cil-cloud-download" } })],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              html: true,
                              content: "Send E-Mail",
                            },
                            expression:
                              "{\n            html: true,\n            content: 'Send E-Mail'\n          }",
                          },
                        ],
                        staticClass: "ml-1",
                        attrs: { size: "sm", color: "info" },
                      },
                      [_c("CIcon", { attrs: { name: "cil-send" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "show_details",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: { color: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleDetails(item, index)
                          },
                        },
                      },
                      [
                        Boolean(item._toggled)
                          ? _c(
                              "span",
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-chevron-circle-up-alt" },
                                }),
                                _vm._v(" Hide"),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              [
                                _c("CIcon", {
                                  attrs: {
                                    name: "cil-chevron-circle-down-alt",
                                  },
                                }),
                                _vm._v(" Details"),
                              ],
                              1
                            ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "details",
            fn: function ({ item, index }) {
              return [
                _c(
                  "CCollapse",
                  {
                    attrs: {
                      show: Boolean(item._toggled),
                      duration: _vm.collapseDuration,
                    },
                  },
                  [
                    _c(
                      "CCardBody",
                      [
                        item.subject
                          ? _c(
                              "CLink",
                              {
                                staticClass: "font-weight-bold",
                                attrs: { to: _vm.subjectLink(item) },
                              },
                              [
                                _vm._v("Go to subject "),
                                _c("CIcon", {
                                  attrs: { name: "cil-arrow-right" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("json-viewer", {
                          attrs: {
                            value: item.properties,
                            "expand-depth": 2,
                            copyable: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "batch_uuid",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" " + _vm._s(item.batch_uuid) + " ")])]
            },
          },
        ]),
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.to) + " / " + _vm._s(_vm.total))]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.8, mtop: 250 + _vm.mcSpinnerMarginTop + "px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }